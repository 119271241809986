import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import axios from 'axios';
import MyGlobleSetting from './MyGlobleSetting';
import swal from 'sweetalert';
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
    
      const data = {
     
        amount:  props.data,
        currency: 'aud',
      }
      axios.post(MyGlobleSetting.api+'/api/stripeintents', data).then((response) => {
        const pr = stripe.paymentRequest({
          country: 'AU',
          currency: 'aud',
          total: {
            label: 'Payment',
            amount:response.data.amount,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });    
  pr.on('paymentmethod', async (ev) => {
   
 
    const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
      response.data.client_secret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    );
  
    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete('fail');
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(response.data.client_secret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

 
    if(token=='' && tabledata=='')
    {
      swal({
   
        text: "Invalid data",
       
      });
      return false;
    }
    const data = {
    
        token: token,
        tableid: tabledata,
      
        textarea:props.textarea,
        name:props.name,
        phone:props.phone,
        email:props.email,
        stripeToken:response.data.id,
        amount:props.data.toFixed(2),
        stripetype:'wallet'
        
      }
    axios.post(MyGlobleSetting.api+'/api/stripepay', data).then((response) => {
           
     if(response.data.success==true) 
     
     {
       swal({
       
        text: "Your order is successfully created  !!",
        icon: "success"
       
      });
      //localStorage.clear();
    window.location.href="/thanks";

    }
    else
    {
      swal({
       
        text: "Payment Failed !!",
        icon: "error"
       
      });
    }


    });
         
        }
      } else {
        var token=localStorage.getItem("devicetoken");
        var tabledata = localStorage.getItem("tableid");
    
     
        if(token=='' && tabledata=='')
        {
          swal({
       
            text: "Invalid data",
           
          });
          return false;
        }
        const data = {
        
          token: token,
          tableid: tabledata,
        
          textarea:'',
          name:props.name,
          phone:props.phone,
          email:props.email,
          stripeToken:response.data.id,
          amount:props.data.toFixed(2),
          stripetype:'wallet'
            
          }
        axios.post(MyGlobleSetting.api+'/api/stripepay', data).then((response) => {
               
         if(response.data.success==true) 
         
         {
           swal({
           
            text: "Your order is successfully created  !!",
            icon: "success"
           
          });
          //localStorage.clear();
        window.location.href="/thanks";
    
        }
        else
        {
          swal({
           
            text: "Payment Failed !!",
            icon: "error"
           
          });
        }
    
    
        });
             
      }
    }
  });

     pr.canMakePayment().then(result => {
       if (result) {
         setPaymentRequest(pr);
       }
     });


      
      });
    

    }
  }, [stripe]);


  

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }

  // Use a traditional checkout form.
  return '';
}

export default CheckoutForm;