import React from 'react';
import logo from './logo.svg';
import axios from 'axios';
import MyGlobleSetting from './MyGlobleSetting';
import swal from 'sweetalert';

function Razorpay(props) {
  
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
    
  }

  async function displayRazorpay() {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

 
    if(token=='' && tabledata=='')
    {
      swal({
   
        text: "Invalid data",
       
      });
      return false;
    }

    // if (!res) {
    //   alert('Razorpay SDK failed to load. Are you online?');
    //   return;
    // }

    // const result = await axios.post('/payment/orders');

    // if (!result) {
    //   alert('Server error. Are you online?');
    //   return;
    // }

    // const { amount, id: order_id, currency } = result.data;
  
    const options = {
      key: props.settings.razorpay_key, // Enter the Key ID generated from the Dashboard
      amount: (props.data*100).toFixed(0),
      currency: props.settings.currency,
      name: props.settings.name,
      handler: async function (response) {

        const data = {
          token: token,
          tableid: tabledata,
          textarea: props.textarea,
          name:props.name,
          phone:props.phone,
          email:props.email,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          amount: props.data,
        };

        axios.post(MyGlobleSetting.api+'/api/razorpay', data).then((response) => {
          document.body.classList.remove('loading-indicator'); 
         if(response.data.success==true) 
         {
           swal({
           
            text: "Your order is successfully created  !!",
            icon: "success"
           
          });
          //localStorage.clear();
        window.location.href="/thanks";
    
        }
        else
        {
          swal({
           
            text: "Payment Failed !!",
            icon: "error"
           
          });
        }
    
    
        });
      },
      theme: {
        color: '#FBAD03',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    
  }

  return (
  
     
        <button className='App-link btn btn-default mt-2 ml-1 mr-1' id="testbtn" onClick={displayRazorpay}>
          Pay Now
        </button>
     
  );

  }
export default Razorpay;