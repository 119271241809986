import logo from './logo.svg';
import React from 'react';
import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';
import Slide from './Slide';

import MyGlobleSetting from './MyGlobleSetting';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";


axios.interceptors.request.use(function (config) {

    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
  
    const token = window.localStorage.token;
    if (token) {
       config.headers.Authorization = `token ${token}`
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(function (response) {
  
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
  
    return response;
  }, function (error) {
    return Promise.reject(error);
  });



class Home extends React.Component {


    constructor(props) {
        super(props);
   
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);

        this.state = {
          slide_background_color:"",
          slide_font_color:"",
          header_color:"black",
          header_font: "white",
          icon_color:"red",
          icon_font:"white",
          successMessage: "",
          resName:"",
          categories: '',
          selectedcategory:'',
          menudata:'',
          subcategorydata:'',
          selectedsubcategory:'',
          modal:'',
          modifierdata:'',
          selectedmenu:'',
          selectedmodifierdata:'',
          selectedbtn:[],
          checked: false,
          localtoken:0,
          cartdata:[],
          view:2,
          tabledata:'',
          tableid:'',
          mainqty: 1,
        };
      }

      toggleswitch(id,val) {
        //this.setState({ selectedbtn[id]: response.data.data });
  }



  goto_page(page)
  {
  
    this.props.history.push(page)
  }



  handleChange2(e) {

    this.setState({ checked:false});


    swal({
       
        text: "You can not select more option !!",
       
      });
   
  }

  openmodaloption()
  {
    swal({
       
      text: "Add option in categories !!",
     
    });
  }
  
  subqty(name,value) {
    value=parseInt(value);   
value=value-1;

if(value<1)
value=1;



localStorage.setItem(name, value);

    this.setState({ checked:false});
  }



  subqty2() {

   var value=parseInt(this.state.mainqty);   
value=value-1;

if(value<1)
value=1;


    this.setState({ mainqty:value});
  }


  addqty2() {
    var value=parseInt(this.state.mainqty);   
value=value+1;

    this.setState({ mainqty:value});
  }



  getcart()
  {

    var token=localStorage.getItem("devicetoken");
    const data = {
    
        token: token
      }
    axios.post(MyGlobleSetting.api+'/api/getcart', data).then((response) => {
           
        this.setState({ cartdata: response.data.data });


            });




  }

  addqty(name,value) {
    

    value=parseInt(value);
    value=value+1;
    
  
    
    localStorage.setItem(name, value);
        
   


        this.setState({ checked:true});
      }



      handleSubmit(event) {
    

        var data = $('.cartform').serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

         
     
      
          axios.post(MyGlobleSetting.api+'/api/addtocart', data).then((response) => {
           



            swal({
       
                text: "Item is successsfully added in cart !!",
                icon: "success"
               
              });
              this.setState({ successMessage:"shakebtn",modal:0})
              this.getcart();
              setTimeout(function () {
                this.setState({ successMessage:""})
              }.bind(this), 4000)
                });




     
        event.preventDefault();
      }


  setClassBtn()
  {
 
    this.setState({ successMessage:""})
  }
  handleChange(e) {
     

    if(e.target.checked==true)
    localStorage.setItem(e.target.name, 1);

    if(e.target.checked==false)
    localStorage.setItem(e.target.name, 0);




    console.log(e.target.checked);
    this.setState({ checked:false});
  }





    componentDidMount(){


      const query = new URLSearchParams(this.props.location.search);
     
      
if(query.get('table')!=null)
{


  const data = {
    
    id: query.get('table')
  }
axios.post(MyGlobleSetting.api+'/api/checktable', data).then((response) => {
       

  if(response.data.success==true)
  {

    localStorage.setItem("tableid",response.data.data.id);

   
  }
  else
  {
    localStorage.setItem("tableid",null);
  }
  


        });


      
         var randomNumber1 = Math.floor(Math.random() * 100) + 1;
         var randomNumber2 = Math.floor(Math.random() * 100) + 1;
         var randomNumber3 = Math.floor(Math.random() * 100) + 1;

         var randomNumber4 = randomNumber1*randomNumber2*randomNumber3;

         localStorage.setItem("devicetoken",(randomNumber4*randomNumber4*randomNumber4)+localStorage.getItem("tableid"))
        
        


}

      



            if(localStorage.getItem("devicetoken")==undefined || localStorage.getItem("devicetoken")==null)
            {
               // alert(localStorage.getItem("devicetoken"));
             var randomNumber1 = Math.floor(Math.random() * 100) + 1;
             var randomNumber2 = Math.floor(Math.random() * 100) + 1;
             var randomNumber3 = Math.floor(Math.random() * 100) + 1;

             var randomNumber4 = randomNumber1*randomNumber2*randomNumber3;

             localStorage.setItem("devicetoken",(randomNumber4*randomNumber4*randomNumber4)+localStorage.getItem("tableid"))
            
            }

  
       
        axios.get(MyGlobleSetting.api+'/api/qrcategory')
        .then(response2 => {


         
          localStorage.setItem("stripe_key_secret",response2.data.data.settings.stripe_key_secret);
          localStorage.setItem("stripe_key",response2.data.data.settings.stripe_key);
          localStorage.setItem("app_id",response2.data.data.settings.app_id);
          localStorage.setItem("location_id",response2.data.data.settings.location_id);
          localStorage.setItem("sq_token",response2.data.data.settings.sq_token);
          localStorage.setItem("sq_type",response2.data.data.settings.sq_type);
          localStorage.setItem("resname",response2.data.data.settings.name);

          localStorage.setItem("view",response2.data.data.settings.qr_category_type );
          localStorage.setItem("header_color",response2.data.data.settings.qr_header_color);
          localStorage.setItem("header_font",response2.data.data.settings.qr_header_font_color);
          localStorage.setItem("header_text",response2.data.data.settings.qr_header_text);
          localStorage.setItem("icon_color",response2.data.data.settings.qr_icon_color);
          localStorage.setItem("icon_font",response2.data.data.settings.qr_icon_font_color);
          localStorage.setItem("logo",response2.data.data.settings.businesslogo);

          localStorage.setItem("currencyIcon",response2.data.data.settings.currency_icon);
if(response2.data.data.settings.is_qr==1 &&  response2.data.data.is_start==1)
{
          axios.get(MyGlobleSetting.api+'/api/category-product/' + response2.data.data.data[0].id)
      .then(response => {
      
       
        this.setState({ 
          
          selectedcategory:  response2.data.data.settings.qr_category_type !=1 ? response2.data.data.data[0].id: "",
          selectedcategoryname:   response2.data.data.settings.qr_category_type !=1 ? response2.data.data.data[0].name: "",
          view: response2.data.data.settings.qr_category_type,  
          header_color: response2.data.data.settings.qr_header_color,  
          header_font: response2.data.data.settings.qr_header_font_color,  
          header_text: response2.data.data.settings.qr_header_text,  
          icon_color: response2.data.data.settings.qr_icon_color,  
          icon_font: response2.data.data.settings.qr_icon_font_color, 
          logo: response2.data.data.settings.businesslogo,  
          slide_background_color: response2.data.data.settings.slide_background_color,  
          slide_font_color: response2.data.data.settings.slide_font_color,  
          resName:response2.data.data.settings.name, 
           categories: response2.data.data.data ,
           localtoken: localStorage.getItem("devicetoken"),
           tableid: localStorage.getItem("tableid"),
           subcategorydata:  response2.data.data.settings.qr_category_type !=1 ? response.data.data.activesubcategory: [],
           menudata:  response2.data.data.settings.qr_category_type !=1 ?response.data.data.menucat : [] }, () => {
       
        this.getcart();
        this.tabledata();
      });
      
     
    });
}
  else{

    if(response2.data.data.is_start != 1)
    {
      this.props.history.push('/restaurantclosed');
    } else {
    this.props.history.push('/qroff');
    }
    return false;
  }


        });


      
      }

      tabledata()
      {


        const data = {
    
          id: this.state.tableid
        }
      axios.post(MyGlobleSetting.api+'/api/checktable', data).then((response) => {
             
      
        if(response.data.success==true)
        {
      
          this.setState({tabledata:response.data.data});
         
         
        }
        else
        {
          swal({
       
            text: "You have not selected any table. Scan the QR code first. ",
           
          });

          this.props.history.push('/ScanQR');
          return false;
        }
        
      
      
              });



      }


      openmenu(id)
      {

        axios.get(MyGlobleSetting.api+'/api/menu-modifier/' + id)
      .then(response => {


      var token = localStorage.getItem("devicetoken");
      var tabledata = localStorage.getItem("tableid");

      var currencyIcon = localStorage.getItem("currencyIcon");
      localStorage.clear();

   localStorage.setItem("devicetoken",token);
   localStorage.setItem("tableid",tabledata);
   localStorage.setItem("currencyIcon",currencyIcon);      
    



        this.setState({ selectedmenu: response.data.data, modifierdata: response.data.data.menumodifier , modal:1})


        if(response.data.data.menumodifier !='' && response.data.data.menumodifier != null)
        {
          response.data.data.menumodifier.map((subdata, i) => {


          if(i==0)
          {
            this.setState({selectedmodifierdata:subdata.id});
          }

          
        });

      }



     

});

      }



      getmenu2(id) {
        this.setState({ selectedcategory: id });

        axios.get(MyGlobleSetting.api+'/api/category-product/' + id)
      .then(response => {
        this.setState({ subcategorydata: response.data.data.activesubcategory,menudata: response.data.data.menucat });
});
      };


      getmenu3(id, name) {
        this.setState({ selectedcategory: id , selectedcategoryname: name});

        axios.get(MyGlobleSetting.api+'/api/category-product/' + id)
      .then(response => {
        this.setState({ subcategorydata: response.data.data.activesubcategory,menudata: response.data.data.menucat });
});
      };




      getmenu = (event) => {
        this.setState({ selectedcategory: event.target.value });

        axios.get(MyGlobleSetting.api+'/api/category-product/' + event.target.value)
      .then(response => {
        this.setState({ subcategorydata: response.data.data.activesubcategory,menudata: response.data.data.menucat });

      


});
      };


sort(valuePath, array){
  let path = valuePath.split('.')  

  return array.sort((a, b) => {
      return getValue(b,path) -  getValue(a,path)    
  });

  function getValue(obj, path){
    path.forEach(path => obj = obj[path])
    return obj;
  }
}
   
back()
{
    this.setState({ modal: 0 });
   
}

 doesFileExist(urlToFile) {
  return urlToFile;
  
}

    render(){

        if(this.state.selectedmenu!=undefined && this.state.selectedmenu!=null && this.state.selectedmenu!='')
        var totprice=parseFloat(this.state.selectedmenu.price);
var sur = 0;
        var selectopt=1;
  return (
    <div className="App">



        {this.state.modal==0 ?
          <div className="site-content">
 


        <header className="site-header header-style-one intro-element header-absolute pages-header" style={{background:"white",position:"fixed",borderBottom:"1px solid lightgrey"}}>
         {this.state.successMessage == true ?   
        <div id="note">
    You smell good.
</div> :
<div className="navigation-area" >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="site-navigation">
                                <div className="site-branding">


                     {this.state.logo &&            <img src={this.doesFileExist(MyGlobleSetting.file+this.state.logo)} style={{width:"100px",height: "35px"}}/> }

                                  
                                   

                                  
                                </div>
                                <div className="header-right-area">
                                   
                                    
                                    <div className="header-card-area" style={{marginRight:"15px"}}>

                                    <a href="#" style={{margin:'0px 5px',backgroundColor:this.state.icon_color}} onClick={()=>this.goto_page('view')}>
                                            <span className="icon-eye" style={{color: this.state.icon_font}}></span>
                                           
                                           
                                        </a>
                                       


                                        <a href="#"  style={{backgroundColor:this.state.icon_color}}id="shakebtn" className={this.state.successMessage} onClick={()=>this.goto_page('cart')}>
                                              <span className="icon-paper-bag"  style={{color: this.state.icon_font}} ></span>
                                            <sup style={{backgroundColor:this.state.header_color,color: this.state.header_font}}>{this.state.cartdata.length}</sup>
                                           
                                        </a>
                                       
                                    </div>
                                 

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                                }
        </header>
      
        

   
      
       
        <div className="food-menu-block style-nine">
            <div className="food-cat-title-area bg-image bg-parallax2" style={{padding:" 88px 2px 15px 2px",backgroundColor:this.state.header_color}}>
                <h2 className="food-cat-title" style={{color: this.state.header_font}}> {this.state.resName}</h2>

                {this.state.tabledata!=undefined && this.state.tabledata!=null && this.state.tabledata !='' && this.state.tabledata?.name != "master_qr" ?
                                  
                                  <span style={{ color: this.state.header_font}}>{this.state.tabledata?.floors?.name ? <span>{this.state.tabledata?.floors?.name} -  </span> : "" }  {this.state.tabledata.name == 'master_qr' ? "QR Order" : this.state.tabledata.name}</span>

                                  :

                                this.state.tabledata?.name != "master_qr"  ? <span style={{ color: this.state.header_font}}>No Table Selected</span>  : ""
                                }


                                {
                                  this.state.header_text != "" ?
                                  <span style={{ color: this.state.header_font}}><br/>{ this.state.header_text}</span>

                                  : ""
                                }


            </div>

{
  this.state.view == 2 ?
<div>
<div class="ct-menu-categories menu-filter mt-2" style={{backgroundColor:this.state.slide_background_color}}>
    <div class="container  p-0" >
<ul class="scroll" style={{padding:"2px"}}>
{this.state.categories!='' && this.state.categories != null ? this.state.categories.map((data, i) => {

  if(data.id == this.state.selectedcategory)
  {
    var cls= "ct-menu-category-item activeli";
  } else {
    cls ="ct-menu-category-item ";
  }
        return (
          <li className ={cls} onClick={()=>this.getmenu3(data.id, data.name)} style={{color: this.state.slide_font_color}}>{data.name}</li>
        );
    }) : ""}

</ul>
</div>

</div>

</div>
  :""
}
      
            {this.state.view==0  || this.state.view==2  ?
 
            <div className="food-menu-block-main ">
               
                <div className="container">
                {this.state.view==0 ?
                <div className="row mt-2 ml-1 mr-1">


    <select className="form-control" style={{width:"100%", border:"2px solid #3c3a3a"}} onChange={this.getmenu} value={this.state.selectedcategory}>
    {this.state.categories!='' && this.state.categories != null ? this.state.categories.map((data, i) => {
        return (
        <option value={data.id}>{data.name}</option>
        );
    }) : ""}
    </select>

                    </div>  : ""}

                    {this.state.view==2 ?  
                    <h5><span style={{color:"lightgrey"}}>Menu: </span><span id="menu-nm">{this.state.selectedcategoryname}</span></h5>
                     : "" }
                    {this.state.subcategorydata!='' && this.state.subcategorydata != null ? this.state.subcategorydata.map((subdata, i) => {
 if(subdata.menusubcat!='' && subdata.menusubcat != null)
 {
 var cls="container p-0 mt-3";
 }
 else
 {
    var cls="container p-0 mt-3 display-none";
 }
return (
                    <div className={cls} style={{border:"1px solid grey"}}>

<div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({selectedsubcategory:subdata.id})}>
{subdata.name} <span style={{color:"black",float:"right"}}>{this.state.selectedsubcategory==subdata.id ? '-':'+'}</span>
    </div>

    {this.state.selectedsubcategory==subdata.id && subdata.menusubcat!='' && subdata.menusubcat != null ?
    <div className="row m-2 mb-3" >
                      

                      {subdata.menusubcat!='' && subdata.menusubcat != null ? subdata.menusubcat.map((data, i) => {
                          var img=data.image;

                          if(img!=null && img!='')
                          {
                            img=MyGlobleSetting.file+img;
                          }
            if(data.todaydisabled == null || data.todaydisabled.length == 0)
            {
            return (
              <div className="col-lg-12 pl-1 pr-1 product mt-3"  onClick={()=>this.openmenu(data.id)} >


              {img=='' || img==null? 
            
            <div className="row ml-2 mr-2 mt-1">
            
            
            <table className="table mctable" style={{marginBottom:"0px"}}>
            <tr>
            <td style={{border:"none"}}>
            <h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>
            
            {data?.name && data.name.substring(0,26)}
            
            </h5>
            
            </td>
            <td style={{border:"none",textAlign:"right",width:"70px"}}>
                  <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
                  </td>
            </tr>
            
                  </table>
            
                  
            
            <p style={{fontSize:"14px"}}>{data?.description && data.description.substring(0,70)}</p> 
            
            
            </div>
            
             : 
            <div className="row mt-1" style={{marginLeft:"2px", marginRight:"2px"}}>
              
              <table className="table mtable" style={{marginBottom:"5px"}}>
            
            <tr style={{border:"none"}}>
              <td style={{width:"80px",border:"none",padding:"0px"}}>
              <img src={this.doesFileExist(img)} style={{width:"80px",height:"70px"}}/> 
              </td>
            
              <td style={{border:"none",padding:"0px"}}>
            
            
                <table className="table mctable" style={{marginBottom:"0px"}}>
            <tr>
            <td style={{border:"none"}}>
            <h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>
            
            {data?.name && data.name.substring(0,26)}
            
            </h5>
            
            </td>
            <td style={{border:"none",textAlign:"right",width:"70px"}}>
                  <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
                  </td>
            </tr>
            
                  </table>
            
                  <p style={{fontSize:"14px"}}>{data?.description && data.description.substring(0,60)}</p>
            
            
              </td>
            
             
            
            
            </tr>
            
                </table>
              
                
            
                </div>
                
                }
            
            
            
            </div>
    
    )}
  }) : ""}
                             
                          </div>

                          :'' }

                    
               </div>    

);
}) : ""}



                    <div className="row m-2">
                      

                    {this.state.menudata!='' && this.state.menudata != null ? this.state.menudata.map((data, i) => {

                      var img=data.image;

                      if(img!=null && img!='')
                      {
                        img=MyGlobleSetting.file+img;
                      }

                      if(data.todaydisabled == null || data.todaydisabled.length == 0)
                      {
        return (
                        <div className="col-lg-12 pl-1 pr-1 product mt-3"  onClick={()=>this.openmenu(data.id)} >


  {img=='' || img==null? 

<div className="row ml-2 mr-2 mt-1">


<table className="table mctable" style={{marginBottom:"0px"}}>
<tr>
<td style={{border:"none"}}>
<h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>

{data?.name && data.name.substring(0,26)}

</h5>

</td>
<td style={{border:"none",textAlign:"right",width:"70px"}}>
      <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
      </td>
</tr>

      </table>



<p style={{fontSize:"14px"}}>{data?.description && data.description.substring(0,60)}</p> 


</div>

 : 
<div className="row mt-1" style={{marginLeft:"2px", marginRight:"2px"}}>
  
  <table className="table mtable" style={{marginBottom:"5px"}}>

<tr style={{border:"none"}}>
  <td style={{width:"80px",border:"none",padding:"0px"}}>
  <img src={this.doesFileExist(img)} style={{width:"80px",height:"70px"}}/> 
  </td>

  <td style={{border:"none",padding:"0px"}}>


    <table className="table mctable" style={{marginBottom:"0px"}}>
<tr>
<td style={{border:"none"}}>
<h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>

{data?.name && data.name.substring(0,26)}

</h5>

</td>
<td style={{border:"none",textAlign:"right",width:"70px"}}>
      <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
      </td>
</tr>

      </table>

      <p style={{fontSize:"14px"}}>{data?.description && data.description.substring(0,60)}</p>


  </td>

 


</tr>

    </table>
  
    

    </div>
    
    }



</div>

)}
}) : ""}
                           
                        </div>
                    </div>
                  
                </div> : "" }






               { this.state.view == 1 &&
                
                
                
                <div className="food-menu-block-main p-2">


{this.state.tableid!='' && this.state.tableid!=null && this.state.categories!='' && this.state.categories != null ? this.state.categories.map((catdata, i) => {
       var cls="container p-0 mt-3";
       
       return (

          <div className={cls} style={{border:"1px solid grey"}} onClick={()=>this.getmenu2(catdata.id)}>

          <div className=" p-2" style={{background:"#494949",fontWeight:"700",color:"white"}} >
          {catdata.name} <span style={{color:"white",float:"right"}}>{this.state.selectedcategory==catdata.id ? '-':'+'}</span>
              </div>
              {this.state.selectedcategory==catdata.id ?


<div className="row m-2">



               


                {this.state.subcategorydata!='' && this.state.subcategorydata != null ? this.state.subcategorydata.map((subdata, i) => {
if(subdata.menusubcat!='' && subdata.menusubcat != null)
{
var cls="container p-0 mt-3";
}
else
{
var cls="container p-0 mt-3 display-none";
}
return (
                <div className={cls} style={{border:"1px solid grey"}}>

<div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({selectedsubcategory:subdata.id})}>
{subdata.name} <span style={{color:"black",float:"right"}}>{this.state.selectedsubcategory==subdata.id ? '-':'+'}</span>
</div>

{this.state.selectedsubcategory==subdata.id && subdata.menusubcat!='' && subdata.menusubcat != null ?
<div className="row m-2 mb-3" >
                  

                  {subdata.menusubcat!='' && subdata.menusubcat != null ? subdata.menusubcat.map((data, i) => {
                      var img=data.image;

                      if(img!=null && img!='')
                      {
                        img=MyGlobleSetting.file+img;
                      }
        return (
          <div className="col-lg-12 pl-1 pr-1 product mt-1"  onClick={()=>this.openmenu(data.id)} >


          {img=='' || img==null? 
        
        <div className="row ml-2 mr-2 mt-1">
        
        
        <table className="table mctable" style={{marginBottom:"0px"}}>
        <tr>
        <td style={{border:"none"}}>
        <h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>
        
        {data?.name && data.name.substring(0,26)}
        
        </h5>
        
        </td>
        <td style={{border:"none",textAlign:"right",width:"70px"}}>
              <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
              </td>
        </tr>
        
              </table>
        
              
        
        <p style={{fontSize:"14px"}}>{data?.description && data.description.substring(0,60)}</p> 
        
        
        </div>
        
         : 
        <div className="row mt-1 " style={{marginLeft:"2px", marginRight:"2px"}}>
          
          <table className="table mtable" style={{marginBottom:"5px"}}>
        
        <tr style={{border:"none"}}>
          <td style={{width:"80px",border:"none",padding:"0px"}}>
          <img src={this.doesFileExist(img)} style={{width:"80px",height:"70px"}}/> 
          </td>
        
          <td style={{border:"none",padding:"0px"}}>
        
        
            <table className="table mctable" style={{marginBottom:"0px"}}>
        <tr>
        <td style={{border:"none"}}>
        <h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>
        
        {data.name.substring(0,26)}
        
        </h5>
        
        </td>
        <td style={{border:"none",textAlign:"right",width:"70px"}}>
              <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
              </td>
        </tr>
        
              </table>
        
              <p style={{fontSize:"14px"}}>{data.description && data.description.substring(0,60)}</p>
        
        
          </td>
        
         
        
        
        </tr>
        
            </table>
          
            
        
            </div>
            
            }
        
        
        
        </div>

);
}) : ""}
                         
                      </div>

                      :'' }

                
           </div>    

);
}) : ""}



                <div className="row m-2">
                  

                {this.state.menudata!='' && this.state.menudata != null ? this.state.menudata.map((data, i) => {

                  var img=data.image;

                  if(img!=null && img!='')
                  {
                    img=MyGlobleSetting.file+img;
                  }
    return (
                    <div className="col-lg-12 pl-1 pr-1 product mt-1"  onClick={()=>this.openmenu(data.id)} >


{img=='' || img==null? 

<div className="row ml-2 mr-2 mt-1">


<table className="table mctable" style={{marginBottom:"0px"}}>
<tr>
<td style={{border:"none"}}>
<h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>

{data?.name && data.name.substring(0,26)}

</h5>

</td>
<td style={{border:"none",textAlign:"right",width:"70px"}}>
  <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
  </td>
</tr>

  </table>



<p style={{fontSize:"14px"}}>{data?.description && data.description.substring(0,60)}</p> 


</div>

: 
<div className="row mt-1" style={{marginLeft:"2px", marginRight:"2px"}}>

<table className="table mtable" style={{marginBottom:"5px"}}>

<tr style={{border:"none"}}>
<td style={{width:"80px",border:"none",padding:"0px"}}>
<img src={this.doesFileExist(img)} style={{width:"80px",height:"70px"}}/> 
</td>

<td style={{border:"none",padding:"0px"}}>


<table className="table mctable" style={{marginBottom:"0px"}}>
<tr>
<td style={{border:"none"}}>
<h5 style={{fontSize:"16px",fontWeight:"800",width:"100%",textAlign:"left",fontFamily:"monospace",marginBottom:"0px"}}>

{data.name.substring(0,26)}

</h5>

</td>
<td style={{border:"none",textAlign:"right",width:"70px"}}>
  <span class="" style={{marginBottom:"0px",fontWeight:"800",fontSize:"14px"}}>   {localStorage.getItem("currencyIcon")} {data.price.toFixed(2)}</span>
  </td>
</tr>

  </table>

  <p style={{fontSize:"14px"}}>{data.description && data.description.substring(0,60)}</p>


</td>




</tr>

</table>



</div>

}



</div>

);
}) : ""}
                       
                    </div>
                    </div>

                                 : 
                                 ''}

              </div>
      
        );
    }) : ""}




               </div>  
                
                
                
                
                
                
                
                }
         
        </div>
        
        

    </div>










    : '' }


{this.state.modal==1 ?
          <div className="site-content">
    <form onSubmit={this.handleSubmit} className="cartform">
      
        {this.state.selectedmenu!='' && this.state.selectedmenu != null ? 
        <div className="food-menu-block style-nine">

<div className="row topbar">
                        <div className="col-12">
                            <div className="site-navigation">
                                <div className="site-branding">
                                <a href="#"  onClick={()=>this.back()}>
                                            <span className="icon-circle-left" style={{fontSize:"32px",color:"white"}}></span>
                                            
                                        </a>
                                </div>
                               
                            </div>
                        </div>
                    </div>

            <div className="food-cat-title-area bg-image bg-overlay bg-parallax2" style={{padding:"170px 0px 80px 0px", backgroundImage: `url(${MyGlobleSetting.file + this.state.selectedmenu.image})`}}>
               
          
                    
                     <div className="food-cat-title" style={{backgroundColor:"rgb(45 46 47 / 52%)", padding:"10px"}}>{this.state.selectedmenu.name}</div>
            </div>
            <div className="food-menu-block-main ">
               
                <div className="container">

                <div className="row m-2">
                
                <h5 className="food-cat-title" style={{width:"100%",fontSize:"20px",textAlign:"center"}}>
                  
                  Price : <b> {localStorage.getItem("currencyIcon")} {this.state.selectedmenu.price.toFixed(2)}</b>

                  
                  </h5>
                <p >{this.state.selectedmenu.description}</p> 
                 </div>

                </div>


<input type="hidden" name="id" value={this.state.localtoken} />

<input type="hidden" name="price" value={this.state.selectedmenu.price} />

<input type="hidden" name="menu_id" value={this.state.selectedmenu.id} />
<input type="hidden" name="name" value={this.state.selectedmenu.name} />



                <div className="row m-0" style={{ padding:"0px 10px"}}>


                {this.state.modifierdata!='' && this.state.modifierdata != null ? this.state.modifierdata.map((subdata, i) => {





 if(subdata.type==1)
 {
 var cls="container p-0 mt-3";
 }
 else
 {
    var cls="container p-0 mt-3 display-none";
 }

 var newdata= JSON.parse(subdata.modifier_data);
var optiontext='';
 if(newdata.option_type==0)
 {
    optiontext='(Optional)'; 
 }

 if(newdata.option_type==1)
 {
    optiontext='(Up To '+ newdata.option_value+')'; 
 }


 if(newdata.option_type==2)
 {
    optiontext='(Must Select '+ newdata.option_value+')'; 
 }

var checkedoption=0;

if( (newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '')

{
    
    
    (newdata.button_name).map((btn, b) => {


        
 var toggleclass='toggle_'+subdata.id+'_'+b;



    var toggleclassvalue=localStorage.getItem(toggleclass);


     if( toggleclassvalue==1)
     {
        checkedoption++;
     }
 


    });


}

var nomore=0;
if(newdata.option_type!=0 && checkedoption==newdata.option_value)
{
    nomore=1; 
}

var iconmark=1;
if(newdata.option_type!=0)
{
if(newdata.option_type==1 && checkedoption<1)
{
  selectopt=0;

  iconmark=0;
}


if(newdata.option_type==2 && checkedoption!=newdata.option_value)
{
  selectopt=0;

  iconmark=0;
}

}
var trcls="table";
if(this.state.selectedmodifierdata!= subdata.id)
{
    var trcls="table display-none";
}
var bord="1px solid grey";
if(iconmark==0)
{
  bord="3px solid red";
}

return (

    <div className={cls} style={{border:bord}}>

<div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({selectedmodifierdata:subdata.id})}>
{subdata.name} {optiontext}

{iconmark==1?
<i class="icon-checkmark ml-2" style={{color:"green",float:"right"}}></i>
:

<i class="icon-blocked ml-2" style={{color:"red",float:"right"}}></i>
}
    </div>

    <table className={trcls} style={{marginBottom:"0px"}}>

    { (newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '' ?(newdata.button_name).map((btn, b) => {


var seloption = [];
var afterchargearray = [];


if(subdata.is_charge_after == '1') {
for(var si= 0; si<newdata.button_name.length; si++)
{
  var toggleclass='toggle_'+subdata.id+'_'+si;
  var toggleclassvalue=localStorage.getItem(toggleclass);
  if(toggleclassvalue==1)
  {
    var price = 0;
    if(newdata.price[si]=='' || newdata.price[si]==null || newdata.price[si]==0)
    price=0;
    price=parseFloat(newdata.price[si]);

    seloption.push({"price":price,"index":si });
  }

}


seloption.sort((a,b) => {
  return  a.price-b.price;
});



for(var so=0; so<seloption.length; so++)
{
  if(so < subdata.charge_after_value)
  {
    afterchargearray.push(seloption[so].index);
  }
}

}

if(afterchargearray.includes(b))
{
 newdata.price[b] = 0;
}


if(newdata.price[b]=='' || newdata.price[b]==null || newdata.price[b]==0)
 newdata.price[b]=0;



 newdata.price[b]=parseFloat(newdata.price[b]);





 var toggleclass='toggle_'+subdata.id+'_'+b;

 var qtyclass='qty_'+subdata.id+'_'+b;
 var priceclass='price_'+subdata.id+'_'+b;


    var toggleclassvalue=localStorage.getItem(toggleclass);

    var qtyclassvalue=localStorage.getItem(qtyclass);

    if(qtyclassvalue==undefined)
    {
        qtyclassvalue=1;
    }

var disabled=0;
     if(nomore==1 && (toggleclassvalue==0 || toggleclassvalue==undefined))
     {
         disabled=1;
     }
 
var temp=nomore+'~~'+toggleclassvalue;

if(toggleclassvalue==1)
{
totprice=totprice+(parseFloat(newdata.price[b])*parseInt(qtyclassvalue));
}


return (

    <tr data={totprice}>
        <th style={{textAlign:"left"}}>{newdata.button_name[b]} </th>

        <th style={{textAlign:"left"}}>{localStorage.getItem("currencyIcon")}{newdata.price[b].toFixed(2)} </th>

        <td>
            
        {newdata.quantity[b]==1?
       
        <div class="customize-controls">


<div class="qty qty_div">


   <span class="qty-subtract"  onClick={()=>this.subqty(qtyclass,qtyclassvalue)}   ><i class="icon-minus"></i></span>
  <input type="text" name={qtyclass} value={qtyclassvalue} className="quantity"  readonly="" style={{padding:"0px", height:"25px",width:"20px", textAlign:"center"}} />
  <span class="qty-add"  onClick={()=>this.addqty(qtyclass,qtyclassvalue)} ><i class="icon-plus"></i></span>
              </div>

</div>

: 

<input type="hidden" name={qtyclass} value="1" className="quantity"  readonly="" style={{padding:"0px", height:"25px",width:"20px", textAlign:"center"}} />


}

<input type="hidden" name={priceclass} value={newdata.price[b]} className="price" />
</td>

        <td>   <label style={{float:"right"}}>
     
        {disabled==0?
        <Toggle  onChange={this.handleChange} value={toggleclassvalue} className="toggle" name={toggleclass} checked={toggleclassvalue==1?true:false}/>
             :

<Toggle  onChange={this.handleChange2} value={toggleclassvalue} className={toggleclass} name={toggleclass} checked={toggleclassvalue==1?true:false}/>
         
        }
   </label></td>
    </tr>











)

    }) : "" }


</table>

    </div>
)

                }) : "" }

                </div>



             </div>



         </div>

          : "Something went wrong" }


    




<div class="row customize-controls p-3">


<div class="qty qty_div">

   <span class="qty-subtract"  onClick={()=>this.subqty2()}   ><i class="icon-minus"></i></span>
  <input type="text" name="mainqty" value={this.state.mainqty} className="quantity"  readonly="" style={{padding:"0px", height:"25px",width:"20px", textAlign:"center"}} />
  <span class="qty-add"  onClick={()=>this.addqty2()} ><i class="icon-plus"></i></span>
              </div>


              <br/>
                 


                
              <span class="m-2" style={{fontSize:"20px"}}>
              Sub Total : <b style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {(this.state.mainqty*totprice).toFixed(2)}</b>
<br/>



{this.state.selectedmenu.item_surcharge!='' && this.state.selectedmenu.item_surcharge != null  && this.state.selectedmenu.item_surcharge.length>0 ? this.state.selectedmenu.item_surcharge.map((attr, i) => {

if(attr.surcharge!= '' && attr.surcharge != null) {
var val=0;
  if(attr.surcharge.type==0)
  {
    val = this.state.mainqty * attr.surcharge.value;
  } else {
    val = (parseFloat(totprice)*parseFloat(this.state.mainqty)*attr.surcharge.value)/100;
  }
  sur +=val;
  return (
<span>


{attr.surcharge.name} {attr.surcharge.type == 1 ? "("+attr.surcharge.value+"%)":"" }:&nbsp;<b style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {val.toFixed(2)}</b><br/>
</span> 
  )
}
}):""}


             


<span>Total : <b style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {((this.state.mainqty*totprice)+sur).toFixed(2)}</b></span>  

                
               
                
                
                
                </span>
</div>






<div><input type="hidden" name="tprice" value={totprice} />
</div>





<div className=" pl-2 pr-2">
<label><b>Special Instructions</b></label>
<center>

<textarea className="form-control" name="textarea" placeholder="Special Instructions" rows="1"></textarea>

  </center>
</div>

    <center>
      {selectopt==1?
<button className="btn btn-primary mt-3" type="submit" style={{width:"95%"}}>Add To Cart</button>

: <button className="btn btn-primary mt-3" type="button" onClick={()=>this.openmodaloption()} style={{width:"95%"}}>Add To Cart</button>
}
</center>

</form>

        </div>

: ' ' }



    </div>
  );
}
}

export default Home;
